import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ContactUsModal from '../pages/contactUs/ContactUsModal';
import paymentBanners from '../assets/payment-banners.png';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const [showContactUs, setShowContactUs] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        {/* About Givetns Section */}
        {/*
        <div className="footer-section">
          <h3>{t('About Givetns')}</h3>
          <ul>
            <li><Link to="/about-us">{t('About Us')}</Link></li>
            <li><Link to="/blog">{t('Blog')}</Link></li>
          </ul>
        </div>
        */}

        {/* Information Section */}
        <div className="footer-section">
          <h3>{t('Information')}</h3>
          <ul>
            {/*<li><Link to="/help">{t('Help')}</Link></li>*/}
            <li><Link to="/terms-conditions">{t('Terms & Conditions')}</Link></li>
            <li><Link to="/privacy">{t('Privacy Policy')}</Link></li>
            {/*<li><Link to="/pricing">{t('Pricing')}</Link></li>*/}
            {/*<li><Link to="/limits">{t('Limits')}</Link></li>*/}
          </ul>
        </div>

        {/* Contacts Section */}
        <div className="footer-section">
        <h3>{t('Contacts')}</h3>
          {/*}
          <div className="footer-item">ИП Иванов Иван Иванович</div>
          <div className="footer-item">12.05.2021, УНП 123456789, Минский городской исполнительный комитет</div>
          <div className="footer-item">01.06.2021, №12345</div>
          <div className="footer-item">220000, Республика Беларусь, г. Минск, ул. Ленина, 1</div>
          <div className="footer-item">Пн-Пт 9:00–18:00</div>
          <div className="footer-item">info@example.com, +375 29 123-45-67</div>
          */}
          {/* Contact Us Button */}
          <Button onClick={() => setShowContactUs(true)} className="contact-us-btn">
            {t('Contact Us')}
          </Button>
        </div>
      </div>

      {/* Payment Banners */}
      <div className="footer-banners">
        <img src={paymentBanners} alt="Payment Banners" />
      </div>

      {/* Contact Us Modal */}
      <ContactUsModal show={showContactUs} handleClose={() => setShowContactUs(false)} />
    </footer>
  );
};

export default Footer;
